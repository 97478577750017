
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url(https://fonts.googleapis.com/css?family=Oxygen);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";



 input {
 	margin-top: 10px;
 	margin-bottom: 10px;
 }
 .carousel-inner {
 	max-height:350px;
 }
 @media(max-width:768px) {
 	h1 {
 		font-size:1.4em;
 	}
 }
 body {
 	background-color:#fff;
 }
 h1,
 h2,
 h3,
 h4,
 h5,
 li,
  a {
 	font-family: 'Roboto', sans-serif;
 }
 p {
 	font-family: 'Oxygen', sans-serif;
 }

 @media(max-width:768px) {
   .smallScreenWidth {
     display:none;
   }
 }
 /* ______________________________________________________________ navbar  */

.navbar-default {
	background-color:#f8f8f8;
	margin-bottom:0;
}

.navbar-default .navbar-nav>li {

}

.navbar-default .navbar-nav>li:hover {
	background-color:#222;
	-webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
       -o-transition: all .5s ease;
      -ms-transition: all .5s ease;
          transition: all .5s ease;
}

.navbar-default .navbar-nav>li>a {
	padding-top:25px;
	padding-bottom:25px;
  font-size:1.1em;
  font-weight:300;
	-webkit-transition: all 1s ease;
     -moz-transition: all 1s ease;
       -o-transition: all 1s ease;
      -ms-transition: all 1s ease;
          transition: all 1s ease;
			color:#444;
}

.navbar-default .navbar-nav>li>a:hover {
	transition: all 0.5s linear;
	color:#fff;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #444;
}

 .navbar .navbar-default {
 	font-size:1.2em;
 }

 .nav>li>a {
 	font-size:1.4em;
 }

 .navbar-brand {
 	font-size:2.0em;
 }

 .navbar-default .navbar-brand {
 	color:#0a335d;
  font-weight:600;
 	padding-top:25px;
	padding-bottom:25px;
 }

 .navbar-default .navbar-brand:hover {
   transition: all 0.5s linear;
 	color:#bbb;
 }



 @media(max-width:767px) {
 	.navbar-default .navbar-brand {
 		padding-top:12px;
 	}
}

/* ______________________________________________________________ Middle Banners  */

.mainBanner {
	background: url('../images/newBack2.jpg') no-repeat center center;
	background-size:cover;
	margin-top:0;
}

.mainHeadline {
	margin-top:250px;
  margin-bottom:250px;
	color:#f8f8f8;
}

.mainHeadline h1 {
	font-size: 3.1em;
  font-weight:600;
	margin-bottom:-15px;
	text-shadow:2px 2px 2px #000;
}

.mainHeadline h2 {
  margin-top:-15px;
  font-weight:300;
  text-shadow:1px 1px 1px #000;
}

.mainHeadline img {
  margin: 0 auto;
}

.btn-main {
  background-color:rgba(0,0,0,.0);
  border:1px solid #fff;
  margin:10px 5px 10px 5px;
  color:#fff;
  font-size:1.2em;
}

.btn-main:hover {
  color:#fff;
  -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
       -o-transition: all .5s ease;
      -ms-transition: all .5s ease;
          transition: all .5s ease;
  background-color:#000;
}

.headerSmall {
  font-weight:400;
}

.infoBanner {
	background-color: rgb(10,51,93);
	color:#f8f8f8;
	border-top:1px solid #f8f8f8;
	border-bottom:1px solid #f8f8f8;
}

.infoBanner .media-object {
	margin:15px 0 15px 0;
}

@media(max-width:767px) {
	.infoBanner .media-object {
		display:none;
	}

	.mainHeadline {
		margin:50px 0 50px 0;
	}
}

 /* ______________________________________________________________ Panels  */

.panel {
  margin-top:25px;
}

 .panel-heading h3 {
 	margin-top:3px;
 }

 .panel-heading {
 	border-radius: 5px;
 }

 .panelButton {
 	margin-bottom: 15px;
 }

 .panel .panel-default {
 	border-bottom-right-radius:5px;
 	border-bottom-left-radius:5px;
 }

 .panel img {
 	border-bottom-right-radius:5px;
 	border-bottom-left-radius:5px;
 }

 .blockStuff {
 	background-color:#fff;

 }

 .blockStuff .row {
   margin:0;
 }

 .blockStuff .col-md-4 {
   padding-left:0;
   padding-right:0;
   margin-bottom:-10px;
 }

.grow:hover {
    transition:all 0.3s ease;
    -webkit-transform: scale(0.98);
    -ms-transform: scale(0.98);
    transform: scale(0.98);
}

.grow {
    transition:all 0.3s ease;
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
}



  /* ______________________________________________________________ Footer  */
footer {
	background:url('../images/footBW.jpg') no-repeat bottom center;
	background-size:cover;
	width:100%;
  border-top:1px solid #f8f8f8;
  margin-top:10px;
}

.upperFooter {
  min-height:400px;
}

.upperFooter h3,
.upperFooter p {
  color:#f8f8f8;
}

.upperFooter img {
  padding:3px;
  border: 1px solid #fff;
}

.lowerFooter {
  border-top:1px solid #f8f8f8;
  background-color:rgba(0,0,0,.5);
  margin-bottom:0;
}
.lowerFooter p {
  padding-top:15px;
}

/* _______________________________________________________________ Spring Hill */

form button {
  margin-left:15px;
}

/* ---------------------------------------------------------------- Hover Stuff */

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #000;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 10px 20px;
}


.hovereffect img {
  display: block;
  position: relative;
  max-width: none;
  width: calc(100% + 20px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(-10px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.2;
  filter: alpha(opacity=20);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-shadow:2px 2px 2px #000;
  text-align: center;
  position: relative;
  font-size: 2.5em;
  overflow: hidden;
  padding: 0.1em 0;
  background-color: transparent;
}

.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

.hovereffect h2:hover {
  text-decoration:none;
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
  color: #FFF;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}


.input-group .form-control {
  margin-top:0;
}

#sfPeriodView input {
  margin-top:0;
}